
    import axios from 'axios'
    import store from '../store/index.js'

    export default {
        async add_admins(params)  {
            return await axios.post('admins/create.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async get_admins()  {
            // return await axios.get('admins/read.php')
            // .then(r =>{
            //     return r
            // })
            // .catch(e =>{
            //     console.log(e);
            // })
            store.commit('get_admins')
        },
        async delete_admins(admin_id)  {
            return await axios.post('admins/delete.php' , {
                admin_id : admin_id
            })
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async getOne(admin_id)  {
            return await axios.post('admins/readOne.php' , {
                admin_id : admin_id
            })
            .then(r =>{
                return r
                
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async update_admins(params)  {
            return await axios.post('admins/update.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async report_admins(params)  {
            return await axios.post('admins/report.php',params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
    }
